<template>
  <div>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | moment($formatDate) }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>
      <template v-slot:[`item.is_original_document`]="{ item }">
        <div v-if="item.is_original_document">ตัวจริง</div>
        <div v-else>สำเนา</div>
      </template>
      <template v-slot:[`item.parcel_delivery`]="{ item }">
        <v-icon v-if="item.parcel_delivery">mdi-check</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.transportation_product`]="{ item }">
        {{ item.transport_data.transportation_product }}
      </template>
      <template v-slot:[`item.other_product`]="{ item }">
        {{ item.transport_data.other_product }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="grey" small outlined rounded @click="edit(item)">
          ดู
        </v-btn>
      </template>
      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="600" v-model="dialogForm">
      <CompleteForm :item="dialogFormData" @onClose="dialogForm = false" />
    </v-dialog>
  </div>
</template>

<script>
import CompleteForm from './CompleteForm';
import ReceivedForm from './ReceivedForm';

export default {
  components: {
    CompleteForm,
    ReceivedForm,
  },
  props: ['search'],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'วันที่', value: 'date', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ประเภท', value: 'car_type', filterable: false },
      { text: 'เอกสาร', value: 'is_original_document', filterable: false },
      { text: 'ส่งพัสดุ', value: 'parcel_delivery', filterable: false },
      { text: 'งานขนส่ง', value: 'transportation_product', filterable: false },
      { text: 'งานอื่น ๆ', value: 'other_product', filterable: false },
      { text: 'เบอร์โทร', value: 'customer_phone_number', filterable: false },
      { text: 'เก็บเพิ่ม', value: 'margin_minus', filterable: false },
      { text: 'คืนเงิน', value: 'margin_plus', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],

    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 2,
      tracking_status: 2,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 10,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) => {
          // this.list = res.result.data;
          this.list = this.prepareProcessArray(res.result.data);
          this.pageCount = res.result.total_page || 1;
          console.log(this.list);
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },

    openDialog() {
      this.dialogForm = true;
    },

    edit(item) {
      this.dialogFormData = item;
      this.dialogForm = true;
    },

    cancel() {},
    prepareProcessArray(process_array) {
      let list = [];
      if (
        process_array &&
        process_array.length &&
        typeof process_array === 'object'
      ) {
        for (var i = 0; i < process_array.length; i++) {
          var o = Object.assign({}, process_array[i]);
          if (
            o.transport_data.transportation_fee - o.transport_data.price <=
            0
          ) {
            o.margin_plus =
              Math.floor(
                ((o.transport_data.transportation_fee -
                  o.transport_data.price) *
                  -1 +
                  Number.EPSILON) *
                  100
              ) / 100;
            o.margin_minus = 0;
          } else {
            o.margin_plus = 0;
            o.margin_minus =
              Math.floor(
                (o.transport_data.transportation_fee -
                  o.transport_data.price +
                  Number.EPSILON) *
                  100
              ) / 100;
          }
          o.is_refund_or_charge = false;
          list.push(o);
        }
      }
      return list;
    },
  },
   watch: {
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (nv != ov) {
          this.filter.start_with = nv;
          this.getData();
        }
      }, 1000);
    },
  }
};
</script>