<template>
  <div>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item.col_h`]="{ item }">
        <v-chip label outlined>{{ item.col_h }}</v-chip>
      </template>

      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>

      <template v-slot:[`item.transportation_product`]="{ item }">
        {{ item.transport_data.transportation_product }}
      </template>
      <template v-slot:[`item.other_product`]="{ item }">
        {{ item.transport_data.other_product }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ item.transport_data.price }}
      </template>
      <template v-slot:[`item.transportation_fee`]="{ item }">
        <!-- {{item.transport_data.transportation_fee }} -->
        <v-text-field
          v-model="item.transport_data.transportation_fee"
          :value="item.transport_data.transportation_fee"
          @blur="saveTransportationFee(item)"
          type="number"
          outlined
          dense
          background-color="white"
          single-line
          hide-details
        >
        </v-text-field>
      </template>

      <template v-slot:[`item.is_original_document`]="{ item }">
        <div v-if="item.is_original_document">ตัวจริง</div>
        <div v-else>สำเนา</div>
      </template>
      <template v-slot:[`item.is_success`]="{ item }">
        {{ item.is_success ? 'สำเร็จ' : 'ไม่สำเร็จ' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="grey" small outlined rounded @click="edit(item)">
          ดู
        </v-btn>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="600" v-model="dialogForm">
      <InProgressForm :item="dialogFormData" @onClose="dialogForm = false" />
    </v-dialog>
  </div>
</template>

<script>
import InProgressForm from './InProgressForm';

export default {
  components: {
    InProgressForm,
  },
  props: [
    'search',
    'carTypeId',
    ],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ประเภท', value: 'car_type', filterable: false },
      { text: 'เอกสาร', value: 'is_original_document', filterable: false },
      { text: 'งานขนส่ง', value: 'transportation_product', filterable: false },
      { text: 'งานอื่น ๆ', value: 'other_product', filterable: false },
      { text: 'ราคา', value: 'price', filterable: false },
      { text: 'ขนส่งเก็บ', value: 'transportation_fee', filterable: false },
      { text: 'ผลลัพธ์', value: 'is_success', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],
    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 2,
      tracking_status: 1,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 10,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) => {
          this.list = res.result.data;
          this.list.forEach((item, i) => {
            this.list[i].transport_data.transportation_fee =
              item.transport_data.transportation_fee == 0
                ? item.transport_data.price
                : item.transport_data.transportation_fee;
          });
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    edit(item) {
      this.dialogFormData = item;
      this.dialogForm = true;
    },
    async saveTransportationFee(item) {
      let payload = {
        process_id: item._id,
        tax_data: {},
        insurance_data: {},
        transport_data: {
          transportation_fee: parseFloat(
            item.transport_data.transportation_fee
          ),
        },
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/edit_process`, body)
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
  },
   watch: {
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (nv != ov) {
          this.filter.start_with = nv;
          this.getData();
        }
      }, 1000);
    },
    carTypeId(nv, ov) {
      if (nv != ov) {
        this.filter.car_type_filter = nv;
        this.getData();
      }
    },
  }
};
</script>