<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h5 primary--text"
      ><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->กำลังดำเนินการ {{item.car_plate}} {{item.province}}
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row class="pt-5">
        <v-col cols="10" offset="1" class="pb-0">
          <v-text-field
            v-model="item.customer_name"
            label="ชื่อลูกค้า"
            outlined
            dense
            background-color="white"
            :error-messages="$v.item.customer_name.$error ? $error_text : ''"
          />
          <v-text-field
            v-model="item.customer_phone_number"
            label="เบอร์โทรลูกค้า"
            disabled
            outlined
            dense
            background-color="white"
            :error-messages="
              $v.item.customer_phone_number.$error ? $error_text : ''
            "
          />
          <v-text-field
            v-model="item.transport_data.transportation_product"
            label="งานขนส่ง"
            outlined
            disabled
            dense
            background-color="white"
          />
          <v-text-field
            v-model="item.transport_data.other_product"
            label="งานอื่น ๆ"
            outlined
            disabled
            dense
            background-color="white"
          />

          <v-radio-group
            class="mt-1 mb-3"
            row
            v-model="item.is_success"
            :error-messages="$v.item.is_success.$error ? $error_text : ''"
          >
            <div class="mr-5">งานสำเร็จ ? :</div>
            <v-radio name="" label="ไม่สำเร็จ" :value="false"></v-radio>
            <v-spacer />
            <v-radio name="" label="สำเร็จ" :value="true"></v-radio>
          </v-radio-group>

          <!-- <v-textarea
            v-model="item.reason_on_fail"
            label="หมายเหตุไม่สำเร็จ"
            outlined
            dense
            background-color="white"
          /> -->
          <v-textarea
            v-model="item.note"
            label="โน๊ต"
            outlined
            dense
            background-color="white"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึกข้อมูล</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  validations: {
    item: {
      customer_name: { required },
      customer_phone_number: { required },
      is_success: { required },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {
    close() {
      this.$emit('onClose');
    },
    async save() {
      let postData = {
        process_id: this.item._id,
        is_success: this.item.is_success,
        reason_on_fail: this.item.reason_on_fail,
        note: this.item.note,
      };
      let body = {
        token: this.$jwt.sign(postData, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/update_on_process_job`, body)
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.close();
    },
  },
  computed: {
    isUpdate() {
      return this.item;
    },
  },
};
</script>

